<template>
  <div
    class="h-[calc(100vh-180px)] min-h-[340px] max-h-[550px] md:max-h-[650px] xxl:max-h-[750px] relative flex items-end"
  >
    <FieldItemTypeImage
      v-if="heroImage"
      v-bind="heroImage.image"
      loading="eager"
      preload
      :config="imageStyle"
      class="block"
      img-class="absolute inset-0 object-cover object-center w-full h-full"
      aria-hidden="true"
    />
    <slot />
  </div>
</template>

<script setup lang="ts">
import type { MediaImageFragment } from '#graphql-operations'

defineProps<{
  heroImage: MediaImageFragment | null
}>()

const imageStyle = defineImageStyle({
  type: 'pictures',
  pictures: {
    sm: {
      width: 600,
      height: 550,
    },
    md: {
      width: 800,
      height: 650,
    },
    lg: {
      width: 1024,
      height: 650,
    },
    xl: {
      width: 1680,
      height: 650,
    },
    xxl: {
      width: 1920,
      height: 750,
    },
    xxxl: {
      width: 2560,
      height: 750,
    },
    xxxxl: {
      width: 3828,
      height: 750,
    },
  },
})
</script>
